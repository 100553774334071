import accounting from "../Assets/Images/accounting.png";
import it from "../Assets/Images/it.png";
import finance from "../Assets/Images/finance.png";
import companysec from "../Assets/Images/companysec.webp";

const servicesData = [
  {
    id: 1,
    title: "Accounting",
    imageUrl: accounting,
    desc: "Our accounting services are tailored to meet the diverse needs of businesses, from startups to well-established corporations. We offer comprehensive solutions to ensure that your financial records are accurate, compliant with regulations, and prepared for strategic decision-making. Our team of experts brings deep industry knowledge and cutting-edge technology to streamline your accounting processes.",
    services: [
      "Full-cycle bookkeeping and financial statements",
      "Tax planning and compliance",
      "Audit-ready financial records",
      "Payroll management and compliance",
      "Cash flow and accounts management",
      "Custom financial reports",
    ],
  },
  {
    id: 2,
    title: "Software",
    imageUrl: it,
    desc: "We specialize in delivering innovative software solutions that drive business success. Whether you need a custom application, system integration, or a cloud-based platform, we have the expertise to bring your vision to life. Our development team works closely with clients to ensure the software is scalable, secure, and built to adapt to the ever-evolving digital landscape.",
    services: [
      "Custom software development",
      "Full-stack web development",
      "SEO for better online visibility",
      "Legacy system modernization",
      "ERP system development",
      "API integration services",
      "Mobile app development",
      "Cloud solutions and DevOps",
    ],
  },
  {
    id: 3,
    title: "Company Secretarial",
    imageUrl: companysec,
    desc: "Our company secretarial services ensure your business remains compliant with legal obligations while maintaining efficient corporate governance. We provide expert assistance in managing statutory requirements, ensuring timely filings, and advising on corporate regulations tailored to your business needs. Our team brings extensive experience in maintaining company records and supporting board governance to foster transparency and smooth business operations.",
    services: [
      "Statutory compliance",
      "Corporate governance support",
      "Annual returns and filings",
      "Advice on corporate structure",
      "Maintenance of statutory registers",
      "Operational audits to improve business efficiency and control processes",
    ],
  },
  {
    id: 4,
    title: "Finance",
    imageUrl: finance,
    desc: "Our finance advisory services are designed to help businesses navigate complex financial landscapes with confidence. We provide insights and strategies that enable organizations to optimize their capital structure, improve profitability, and achieve sustainable growth. Whether you're looking to manage risks, raise capital, or plan for mergers and acquisitions, our team of financial experts is here to support your goals.",
    services: [
      "Strategic financial planning",
      "Investment advisory",
      "Cash flow forecasting",
      "Debt restructuring and risk management",
      "Valuation for mergers and acquisitions",
      "Profitability analysis",
    ],
  },
];


export default servicesData;
