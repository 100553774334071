import React from "react";
import "./ClientStat.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ClientStat = ({
  icon,
  cardCompany,
  cardStat,
}) => {
  return (
    <div className="client-stat-card">
      <div className="client-stat-outer-circle">
        <div className="client-stat-inner-circle">
          <FontAwesomeIcon
            icon={icon}
            style={{ fontSize: "xxx-large" }}
          />
        </div>
      </div>

      <h2 className="client-stat-header">{ cardStat}</h2>
      <p className="client-stat-text">{ cardCompany}</p>
    </div>
  );
};

export default ClientStat;
