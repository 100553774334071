import "./App.css";
import AboutUs from "./Components/About Us/AboutUs";
import Clients from "./Components/Clients/Clients";
import ContactUs from "./Components/ContactUs/ContactUs";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Services from "./Components/Services/Services";
function App() {
  return (
    <div className="poppins-regular App">
      <Navbar />
      <Home />
      <AboutUs />
      <Clients />
      <Services />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
