import React, { useState } from "react";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import toastr from "toastr";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaRef = React.createRef();

  // Update state on input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id.split("-")[2]]: e.target.value,
    });
  };

  // Handle reCAPTCHA success
  const handleRecaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      toastr.error("Please complete the reCAPTCHA");
      return;
    }

    setIsSubmitting(true);

    try {
      // Send EmailJS email
      const templateParams = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      };

      // Send the email
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      toastr.success("Message sent successfully!");
      setFormData({ name: "", email: "", phone: "", message: "" }); // Clear form

      if (recaptchaRef.current) {
        recaptchaRef.current.reset(); // Reset reCAPTCHA
      }
    } catch (error) {
      console.log(error);
      toastr.error(
        "There was an error sending your message. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contactus">
      <div className="contactus-header">
        <div className="contactus-header-content">
          <h3>Are You Ready To Elevate Your Business?</h3>
          <p>
            Achieve success with seamless solutions and exceptional services.
            Let us help you reach new heights in business growth and efficiency
          </p>
          <a className="btn-white" href="#contactus">
            Book Now <FontAwesomeIcon icon={faArrowRight} />
          </a>
        </div>
      </div>

      <div className="contactus-form">
        <h4 className="h3-aboutus-text text-center">Contact Us</h4>
        <h1 className="h1-aboutus-heading text-center">
          Let us know your requirement
        </h1>

        <form onSubmit={handleSubmit}>
          <div className="row" style={{ marginTop: "5%" }}>
            <div className="col-md-4 col-xs-12">
              <input
                type="text"
                className="form-control no-shadow"
                id="contactus-form-name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 col-xs-12">
              <input
                type="email"
                className="form-control no-shadow"
                id="contactus-form-email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 col-xs-12">
              <input
                type="tel"
                className="form-control no-shadow"
                id="contactus-form-phone"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col">
              <textarea
                placeholder="Message"
                className="form-control no-shadow"
                id="contactus-form-message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>

          <div
            className="row justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcfJmYqAAAAACmp__PWWn7kd85l73kWydojS-rP" // Replace with your reCAPTCHA site key
              onChange={handleRecaptchaChange}
            />
          </div>

          <div
            className="row justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <button
              className="contactus-form-submit-btn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
