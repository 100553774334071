const FeedbacksList = [
  {
    imgUrl: "../Assets/Images/customer.png",
    cardHeading: "Great Services, Recommended",
    cardDesc:
      "The team provided exceptional service, and the results were beyond expectations. I highly recommend them for their professionalism and dedication to delivering quality work.",
    cardCompany: "Niru Lanka",
    cardRole: "CEO",
    cardRating: "4.4/5",
  },
  {
    imgUrl: "../Assets/Images/customer.png",
    cardHeading: "Highly Professional",
    cardDesc:
      "Working with them was a smooth experience. They understood our needs and provided timely solutions. The team's expertise in backend development was impressive.",
    cardCompany: "M&A",
    cardRole: "Manager",
    cardRating: "4.8/5",
  },
  {
    imgUrl: "../Assets/Images/customer.png",
    cardHeading: "Excellent Collaboration",
    cardDesc:
      "Their design team did an amazing job. They were very responsive to feedback and delivered a user-friendly interface that exceeded our expectations.",
    cardCompany: "R F Marketing",
    cardRole: "Director",
    cardRating: "4.7/5",
  },
  {
    imgUrl: "../Assets/Images/customer.png",
    cardHeading: "Outstanding Support",
    cardDesc:
      "Their expertise in DevOps and cloud management made a significant difference in optimizing our infrastructure. We saw improvements in both performance and reliability.",
    cardCompany: "KAT Holdings",
    cardRole: "Owner",
    cardRating: "4.6/5",
  },
  {
    imgUrl: "../Assets/Images/customer.png",
    cardHeading: "Impressive Work",
    cardDesc:
      "They delivered exceptional data analytics services. Their insights were crucial in driving key business decisions. I look forward to working with them again.",
    cardCompany: "Ganlo Travels",
    cardRole: "CEO",
    cardRating: "4.9/5",
  },
  {
    imgUrl: "../Assets/Images/customer.png",
    cardHeading: "Reliable and Efficient",
    cardDesc:
      "The mobile app they developed for us was feature-rich and user-friendly. It helped us expand our customer base significantly. I would definitely recommend their services.",
    cardCompany: "Appify",
    cardRole: "Manager",
    cardRating: "4.0/5",
  },
];

export default FeedbacksList;
