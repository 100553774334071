import React from "react";
import "./AboutUs.css";
import aboutusImg from "../../Assets/Images/aboutusimg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

const AboutUs = () => {
  return (
    <section id="aboutus" className="">
      <div className="container text-left">
        <div className="row justify-content-around">
          <div className="col-md-12 col-lg-4">
            <img src={aboutusImg} className="img-aboutus" alt="main workers" />
          </div>
          <div className="col-md-12 col-lg-8 ">
            <div className="row aboutus-container">
              <h4 className="h3-aboutus-text">About Us</h4>
              <h1 className="h1-aboutus-heading">More About IMSOutsource</h1>
              <p>
                At IMSOutsource, we provide comprehensive business outsourcing
                solutions with a focus on finance, accounting, and IT services.
                Our mission is to help businesses streamline operations, reduce
                costs, and enhance productivity by delivering expert outsourcing
                services that cater to your unique needs.
              </p>
              <p style={{ marginTop: "10px" }}>
                With years of expertise across multiple industries, we
                understand that every business is unique. That’s why we offer
                customized outsourcing solutions tailored to meet your specific
                needs.
              </p>
              <div className="aboutus-list">
                <div>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="aboutus-list-icon"
                  />{" "}
                  Seamless Finance Operations
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="aboutus-list-icon"
                  />{" "}
                  Reliable Accounting Solutions
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="aboutus-list-icon"
                  />{" "}
                  Company Secreteries Services
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="aboutus-list-icon"
                  />{" "}
                  Advanced IT Services
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
