import React, { useState } from "react";
import "./Clients.css";
import FeedbacksList from "../../Utils/client-feedbacks-list";
import StatsList from "../../Utils/client-stats-list";
import ClientFeedback from "./ClientFeedback/ClientFeedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ClientStat from "./ClientStat/ClientStat";

const Clients = () => {
  const [currentFeedback, setCurrentFeedback] = useState(0); // Track current page
  const FeedbacksPerPage = 3;

  // Calculate the indices for the current slice of cards
  const startFeedbackIndex = currentFeedback * FeedbacksPerPage;
  const endFeedbackIndex = startFeedbackIndex + FeedbacksPerPage;
  const currentFeedbacks = FeedbacksList.slice(
    startFeedbackIndex,
    endFeedbackIndex
  );

  const handleNext = () => {
    if (endFeedbackIndex < FeedbacksList.length) {
      setCurrentFeedback(currentFeedback + 1);
    }
  };

  const handlePrev = () => {
    if (currentFeedback > 0) {
      setCurrentFeedback(currentFeedback - 1);
    }
  };

  return (
    <section id="clients">
      <h4 className="h3-aboutus-text text-center">Top Clients</h4>
      <h1 className="h1-aboutus-heading text-center">
        Let's here from our customers
      </h1>
      <div>
        <div className="card-list container">
          {currentFeedbacks.map((cardData, index) => (
            <ClientFeedback
              key={index}
              imgUrl={cardData.imgUrl}
              cardHeading={cardData.cardHeading}
              cardDesc={cardData.cardDesc}
              cardCompany={cardData.cardCompany}
              cardRole={cardData.cardRole}
              cardRating={cardData.cardRating}
            />
          ))}
        </div>

        <div className="pagination-controls">
          <button
            onClick={handlePrev}
            disabled={currentFeedback === 0}
            className="btn-prev"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            onClick={handleNext}
            disabled={endFeedbackIndex >= FeedbacksList.length}
            className="btn-next"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
      <div className="client-stat-cards">
        {StatsList.map((data, index) => (
          <ClientStat
            key={index}
            icon={data.cardIcon}
            cardCompany={data.cardHeader}
            cardStat={data.cardValue}
          />
        ))}
      </div>
    </section>
  );
};

export default Clients;
