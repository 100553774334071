import React from "react";
import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import workerImg from "../../Assets/Images/workers.png";

const Home = () => {
  return (
    <section id="home" className="container text-center">
      <div className="row justify-content-around">
        <div className="col-md-12 col-lg-4">
          <div className="row text-align-left">
            <h6 className="home-h6">
              Expert in accounting, business solutions and IT
            </h6>
            <h1 className="home-h1">Elevate Your Business with Us</h1>
            <p className="home-text">
              IMSOutsource offers professional outsourcing services in
              accounting, financial reporting, secretarial services, tax
              compliance, audits, and IT solutions. We tailor our services to
              meet your business needs, ensuring compliance and efficiency while
              reducing operational costs. Our expert team helps streamline your
              processes, so you can focus on growth.
            </p>
            <div style={{ paddingLeft: "5%", marginTop: "5%" }}>
              <a className="home-appointment-btn" href="#contactus">
                Book an appointment <FontAwesomeIcon icon={faCalendar} />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8 ">
          <img src={workerImg} className="img-worker" alt="main workers" />
        </div>
      </div>
    </section>
  );
};

export default Home;
