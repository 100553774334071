import React, { useRef, useEffect, useState } from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import servicesData from "../../Utils/services-list";

const Services = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const sliderElement = sliderRef.current; // Copy ref value to local variable
    const cards = sliderElement.querySelectorAll(".slider-card");

    const slide = (direction) => {
      cards[activeIndex].classList.remove("active");

      let newIndex;
      if (direction === "next") {
        newIndex = (activeIndex + 1) % servicesData.length;
      } else if (direction === "prev") {
        newIndex =
          (activeIndex - 1 + servicesData.length) % servicesData.length;
      }

      setActiveIndex(newIndex);
      cards[newIndex].classList.add("active");
    };

    const handleNext = () => slide("next");
    const handlePrev = () => slide("prev");

    sliderElement
      .querySelector(".btn-next")
      .addEventListener("click", handleNext);
    sliderElement
      .querySelector(".btn-prev")
      .addEventListener("click", handlePrev);

    return () => {
      sliderElement
        .querySelector(".btn-next")
        .removeEventListener("click", handleNext);
      sliderElement
        .querySelector(".btn-prev")
        .removeEventListener("click", handlePrev);
    };
  }, [activeIndex]);

  const activeService = servicesData[activeIndex]; // Get the current active service

  return (
    <section id="services">
      <h4 className="h3-aboutus-text text-center">Our Services</h4>
      <h1 className="h1-aboutus-heading text-center">
        Let's take a look at our services
      </h1>
      <div ref={sliderRef}>
        <div className="slider">
          <div className="slider-container">
            {servicesData.map((service, index) => {
              // Determine classes based on the card's index
              const cardClasses = [
                "slider-card",
                index === 0 ? "slider-card-left" : "",
                index === servicesData.length - 1 ? "slider-card-right" : "",
                index === activeIndex ? "active" : "",
              ]
                .filter(Boolean)
                .join(" ");

              return (
                <div
                  key={service.id}
                  className={cardClasses}
                  style={{
                    backgroundImage: `url(${service.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    opacity: "0.8",
                  }}
                >
                  <h3>{service.title}</h3>
                </div>
              );
            })}
          </div>
        </div>
        <div className="pagination-controls">
          <button className="btn-prev">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button className="btn-next">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>

      <div className="services-details-container">
        {/* Display the active service's title, description, and services */}
        <h3>{activeService.title}</h3>
        <div className="content">
          <p>{activeService.desc}</p>
          <div className="aboutus-list">
            {activeService.services &&
              activeService.services.map((servicePoint, index) => (
                <div key={index}>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="aboutus-list-icon"
                  />{" "}
                  {servicePoint}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
