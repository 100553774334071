import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import Logo from "../../Assets/Images/logo.png";

const Navbar = () => {
  const [activeButton, setActiveButton] = useState("Home");
  const isProgrammaticScroll = useRef(false);

  const handleNavBtnClick = (buttonName) => {
    setActiveButton(buttonName); // Set the clicked button as active
    isProgrammaticScroll.current = true; // Mark the scroll as programmatic

    // Scroll to the section programmatically
    document
      .getElementById(`${buttonName.toLowerCase()}`)
      .scrollIntoView({
        behavior: "smooth",
      });

    // Reset programmatic scroll after a delay (same as scroll animation duration)
    setTimeout(() => {
      isProgrammaticScroll.current = false;
    }, 1000); // Adjust time if the scroll duration differs
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isProgrammaticScroll.current) {
        return; // Skip if the scroll was triggered programmatically
      }

      const homeSection = document.getElementById("home");
      const aboutUsSection = document.getElementById("aboutus");
      const clientsSection = document.getElementById("clients");
      const servicesSection = document.getElementById("services");
      const contactUsSection = document.getElementById("contactus");

      const scrollPosition = window.scrollY + 200; // Add an offset to trigger the active class earlier

      // Check if the scroll position is within each section's boundaries
      if (
        homeSection &&
        scrollPosition >= homeSection.offsetTop &&
        scrollPosition < aboutUsSection.offsetTop
      ) {
        setActiveButton("Home");
      } else if (
        aboutUsSection &&
        scrollPosition >= aboutUsSection.offsetTop &&
        scrollPosition < clientsSection.offsetTop
      ) {
        setActiveButton("AboutUs");
      } else if (
        clientsSection &&
        scrollPosition >= clientsSection.offsetTop &&
        scrollPosition < servicesSection.offsetTop
      ) {
        setActiveButton("Clients");
      } else if (
        servicesSection &&
        scrollPosition >= servicesSection.offsetTop &&
        scrollPosition < contactUsSection.offsetTop
      ) {
        setActiveButton("Services");
      } else if (
        contactUsSection &&
        scrollPosition >= contactUsSection.offsetTop
      ) {
        setActiveButton("ContactUs");
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg sticky-top bg-light">
      <div className="container-fluid navbar-space">
        <a
          className={`navbar-brand btn-primary ${
            activeButton === "Home" ? "active" : ""
          }`}
          href="#home"
          onClick={() => handleNavBtnClick("Home")}
        >
          <img src={ Logo} alt="company logo" className="navbar-logo" />
        </a>
        <button
          className="navbar-toggler navbar-btn-margin"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          style={{ justifyContent: "end" }}
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className={`nav-link navbar-btn-default ${
                  activeButton === "Home" ? "active" : ""
                }`}
                aria-current="page"
                href="#home"
                onClick={() => handleNavBtnClick("Home")}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link navbar-btn-default ${
                  activeButton === "AboutUs" ? "active" : ""
                }`}
                href="#aboutus"
                onClick={() => handleNavBtnClick("AboutUs")}
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link navbar-btn-default ${
                  activeButton === "Clients" ? "active" : ""
                }`}
                href="#clients"
                onClick={() => handleNavBtnClick("Clients")}
              >
                Clients
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link navbar-btn-default ${
                  activeButton === "Services" ? "active" : ""
                }`}
                href="#services"
                onClick={() => handleNavBtnClick("Services")}
              >
                Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link navbar-btn-default ${
                  activeButton === "ContactUs" ? "active" : ""
                }`}
                href="#contactus"
                onClick={() => handleNavBtnClick("ContactUs")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
