import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faSquareEnvelope, faSquareParking, faSquarePhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const openWhatsapp = () => {
    const phoneNumber = "94718528660"; // Replace with the desired phone number
    const message = "Hello!"; // Optional: Replace with your custom message
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  const openLinkedIn = () => {
    const linkedInProfileUrl = "https://www.linkedin.com/in/leel-indrajith/"; // Replace with the actual LinkedIn profile URL
    window.open(linkedInProfileUrl, "_blank");
  };
  return (
    <div>
      <div className="row footer-container">
        <div className="col p-5">
          <h3 className="h3-aboutus-text">IMSOutsource</h3>
          <p>
            Expert in accounting, business solutions, finance, Company
            Secretarial and IT
          </p>
          <div className="d-flex">
            <FontAwesomeIcon
              icon={faFacebook}
              size="2x"
              className="m-2 icon-color"
            />
            <FontAwesomeIcon
              icon={faWhatsapp}
              onClick={openWhatsapp}
              size="2x"
              className="m-2 icon-color"
            />
            <FontAwesomeIcon
              icon={faLinkedin}
              onClick={openLinkedIn}
              size="2x"
              className="m-2 icon-color"
            />
          </div>
        </div>
        <div className="col p-5">
          <div className="d-flex flex-column">
            <h4>Quick Links</h4>
            <a href="#home">Home</a>
            <a href="#aboutus">About Us</a>
            <a href="#services">Services</a>
          </div>
        </div>
        <div className="col p-5">
          <div className="">
            <h4>Get In Touch</h4>
            <div className="d-flex flex-column">
              <div className="d-flex">
                <FontAwesomeIcon
                  icon={faSquarePhone}
                  size="2x"
                  className="m-2 icon-color "
                />
                <div className=" mt-3">0718707754</div>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon
                  icon={faSquareEnvelope}
                  size="2x"
                  className="m-2 icon-color"
                />
                <div className="mt-3">imsoutsource@gmail.com</div>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon
                  icon={faSquareParking}
                  size="2x"
                  className="m-2 icon-color "
                />
                <div className=" mt-3">
                  247/4, Hoarana Road, Wekada, Panadura
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row footer-row">
        <p className="text-center"> © 2024 - All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
