import React from "react";
import ClientImg from "../../../Assets/Images/customer.png";
import "./ClientFeedback.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUser } from "@fortawesome/free-solid-svg-icons";

const ClientFeedback = ({
  imgUrl,
  cardHeading,
  cardDesc,
  cardCompany,
  cardRole,
  cardRating,
}) => {
  return (
    <div className="card" style={{ width: "18rem" }}>
      <img src={ClientImg} className="card-img-top" alt={cardHeading} />
      <div className="card-body">
        <h5 className="card-title">{cardHeading}</h5>
        <p className="card-text">{cardDesc}</p>
        <div className="card-child-row">
          <p className="card-company-info ">
            <strong>
              <FontAwesomeIcon className="card-icon" icon={faLocationDot} />
            </strong>{" "}
            {cardCompany}
          </p>
          <p className="card-company-info">
            <strong>
              <FontAwesomeIcon className="card-icon" icon={faUser} />
            </strong>{" "}
            {cardRole}
          </p>
        </div>
        <div className="card-rating">
          <p className="card-rating-header">{cardRating} </p>
          <p style={{fontSize:"x-small"}}>Ratings</p>
        </div>
      </div>
    </div>
  );
};

export default ClientFeedback;
