import {
  faBuilding,
  faUsers,
  faDollarSign,
  faLaptop
} from "@fortawesome/free-solid-svg-icons";

const StatsList = [
  {
    cardIcon: faBuilding,
    cardHeader: "Companies",
    cardValue: "450+",
  },
  {
    cardIcon: faUsers,
    cardHeader: "Clients",
    cardValue: "600+",
  },
  {
    cardIcon: faDollarSign,
    cardHeader: "Secreteries",
    cardValue: "400+",
  },
  {
    cardIcon: faLaptop,
    cardHeader: "Projects",
    cardValue: "100+",
  },
];

export default StatsList;
